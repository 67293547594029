import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Container from "@mui/material/Container"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Alert from "@mui/material/Alert"
import Chip from "@mui/material/Chip"
import MuiLink from "@mui/material/Link"
import PropTypes from "prop-types"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"

import useMediaQuery from "@mui/material/useMediaQuery"
// import Button from "@mui/material/Button"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import ImageListItemBar from "@mui/material/ImageListItemBar"

import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary"
import Diversity3Icon from "@mui/icons-material/Diversity3"
import LocalCafeIcon from "@mui/icons-material/LocalCafe"

import axios from "axios"

import { PhotoProvider, PhotoView } from "react-photo-view"
import "react-photo-view/dist/react-photo-view.css"
import "./css/coolAlbum.css"

import Loading from "../components/Loading/index"

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        sx={{
          margin: "auto",
        }}
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
}

const CoolAlbumPage = ({ data, location }) => {
  const [gear, setGear] = React.useState([])
  const [progress, setProgress] = React.useState(0)

  const postData = async () => {
    const url = "https://api.czhlove.cn/v1/blog/getDzxcList"
    try {
      const response = await axios.get(url)
      setGear(response.data.data)
      setProgress(100)
    } catch (error) {
      console.error("请求失败", error)
    }
  }

  React.useEffect(() => {
    postData()
    const timer = setInterval(() => {
      setProgress(prevProgress => prevProgress + 10)
    }, 800)

    return () => {
      clearInterval(timer)
    }
  }, [])

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  const categories = Object.keys(gear).reduce((acc, key) => {
    const category = gear[key].category_name
    if (!acc.includes(category)) {
      acc.push(category)
    }
    return acc
  }, [])
  const isPC = useMediaQuery("(min-width:600px)")

  function toggleFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen()
    } else {
      const elements = document.getElementsByClassName("PhotoView-Portal")
      const element = elements[0]
      if (element) {
        element.requestFullscreen()
      }
    }
  }
  const FullScreenIcon = props => {
    const [fullscreen, setFullscreen] = React.useState(false)
    React.useEffect(() => {
      document.onfullscreenchange = () => {
        setFullscreen(Boolean(document.fullscreenElement))
      }
    }, [])
    return (
      <svg
        className="PhotoView-PhotoSlider__toolbarIcon"
        fill="white"
        width="44"
        height="44"
        viewBox="0 0 768 768"
        {...props}
      >
        {fullscreen ? (
          <path d="M511.5 256.5h96v63h-159v-159h63v96zM448.5 607.5v-159h159v63h-96v96h-63zM256.5 256.5v-96h63v159h-159v-63h96zM160.5 511.5v-63h159v159h-63v-96h-96z" />
        ) : (
          <path d="M448.5 160.5h159v159h-63v-96h-96v-63zM544.5 544.5v-96h63v159h-159v-63h96zM160.5 319.5v-159h159v63h-96v96h-63zM223.5 448.5v96h96v63h-159v-159h63z" />
        )}
      </svg>
    )
  }

  return (
    <Layout
      location={location}
      title={
        <Box sx={{ display: "flex", alignItems: "center", }}>
          <IconButton
            size="small"
            sx={{
              mr: 1,
              backgroundColor: "action.selected",
              color: "text.primary",
            }}
          >
            <PhotoLibraryIcon fontSize="small" />
          </IconButton>
          {"电子相册"}
        </Box>
      }
    >
      <Seo title={"刘承相册"} />
      <Container
        maxWidth="string"
        disableGutters
        sx={{
          maxWidth: "692px",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          "@media (max-width: 600px)": {
            gap: "1.5rem",
            px: "1.5rem",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            aria-label="nav tabs example"
            value={1}
            sx={{
              "& .MuiTabs-flexContainer": {
                gap: "1rem",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "text.primary",
                height: "1px",
              },
            }}
          >
            <Tab
              label="博客"
              component="a"
              href="/"
              icon={<LocalCafeIcon />}
              iconPosition="start"
              sx={{
                textTransform: "capitalize",
                fontWeight: 400,
                minWidth: "60px",
                "&.Mui-selected": { color: "text.primary" },
              }}
            />
            <Tab
              label="相册"
              component="a"
              href="/coolAlbum"
              icon={<PhotoLibraryIcon />}
              iconPosition="start"
              sx={{
                textTransform: "capitalize",
                fontWeight: 400,
                minWidth: "60px",
                "&.Mui-selected": { color: "text.primary" },
              }}
            />
            <Tab
              label="博友们"
              component="a"
              href="/friend-links"
              icon={<Diversity3Icon />}
              iconPosition="start"
              sx={{
                textTransform: "capitalize",
                fontWeight: 400,
                minWidth: "60px",
                "&.Mui-selected": { color: "text.primary" },
              }}
            />
          </Tabs>
        </Box>
        <Alert
          severity="info"
          sx={{
            backgroundColor: "primary.light",
            display: "flex",
            alignItems: "center",
            color: "primary.dark",
            "& .MuiSvgIcon-root": { color: "primary.dark" },
          }}
          icon={"😎🔞"}
        >
          电子相册
        </Alert>

        {progress < 100 ? (
          <CircularProgressWithLabel value={progress} />
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {categories.map(category => (
              // defaultExpanded
              <Accordion
                elevation={0}
                sx={{
                  "& .MuiButtonBase-root.MuiAccordionSummary-root, .MuiAccordionDetails-root":
                    { padding: 0 },
                }}
              >
                <AccordionSummary
                  id={category + "-header"}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  sx={{
                    "& .MuiAccordionSummary-content": {
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "1rem",
                    },
                  }}
                >
                  <MuiLink
                    variant="h6"
                    underline="none"
                    sx={{
                      color: "text.primary",
                      fontWeight: "500",
                    }}
                    component="a"
                    href={"#" + category}
                  >
                    {category}
                  </MuiLink>
                  <Chip
                    disabled
                    size="small"
                    label={`${
                      Object.keys(gear).filter(
                        key => gear[key].category_name === category
                      ).length
                    } 张图片`}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <PhotoProvider
                      loadingElement={<Loading />}
                      toolbarRender={({ rotate, onRotate, onScale, scale }) => {
                        return (
                          <>
                            <svg
                              className="PhotoView-PhotoSlider__toolbarIcon"
                              width="44"
                              height="44"
                              viewBox="0 0 768 768"
                              fill="white"
                              onClick={() => onScale(scale + 0.2)}
                            >
                              <path d="M384 640.5q105 0 180.75-75.75t75.75-180.75-75.75-180.75-180.75-75.75-180.75 75.75-75.75 180.75 75.75 180.75 180.75 75.75zM384 64.5q132 0 225.75 93.75t93.75 225.75-93.75 225.75-225.75 93.75-225.75-93.75-93.75-225.75 93.75-225.75 225.75-93.75zM415.5 223.5v129h129v63h-129v129h-63v-129h-129v-63h129v-129h63z" />
                            </svg>
                            <svg
                              className="PhotoView-PhotoSlider__toolbarIcon"
                              width="44"
                              height="44"
                              viewBox="0 0 768 768"
                              fill="white"
                              onClick={() => onScale(scale - 0.2)}
                            >
                              <path d="M384 640.5q105 0 180.75-75.75t75.75-180.75-75.75-180.75-180.75-75.75-180.75 75.75-75.75 180.75 75.75 180.75 180.75 75.75zM384 64.5q132 0 225.75 93.75t93.75 225.75-93.75 225.75-225.75 93.75-225.75-93.75-93.75-225.75 93.75-225.75 225.75-93.75zM223.5 352.5h321v63h-321v-63z" />
                            </svg>
                            <svg
                              className="PhotoView-PhotoSlider__toolbarIcon"
                              onClick={() => onRotate(rotate + 90)}
                              width="44"
                              height="44"
                              fill="white"
                              viewBox="0 0 768 768"
                            >
                              <path d="M565.5 202.5l75-75v225h-225l103.5-103.5c-34.5-34.5-82.5-57-135-57-106.5 0-192 85.5-192 192s85.5 192 192 192c84 0 156-52.5 181.5-127.5h66c-28.5 111-127.5 192-247.5 192-141 0-255-115.5-255-256.5s114-256.5 255-256.5c70.5 0 135 28.5 181.5 75z" />
                            </svg>
                            {document.fullscreenEnabled && (
                              <FullScreenIcon onClick={toggleFullScreen} />
                            )}
                          </>
                        )
                      }}
                      overlayRender={({ index }) => {
                        const keyArr = Object.keys(gear).filter(
                          key => gear[key].category_name === category
                        )
                        const filteredArray = keyArr.map(item => {
                          const key = Object.keys(gear).find(
                            key => item === key
                          )
                          if (key) {
                            return gear[key]
                          }else{
                            return {}
                          }
                        })
                        if (
                          Object.keys(gear).filter(
                            key => gear[key].category_name === category
                          )
                        ) {
                          return (
                            <div className="desc">
                              <div>
                                分类：{filteredArray[index].category_name}
                              </div>
                              <div>标题：{keyArr[index]}</div>
                              <div>描述：{filteredArray[index].desc}</div>
                              <div>时间：{filteredArray[index].uploadtime}</div>
                            </div>
                          )
                        }
                      }}
                    >
                      <ImageList
                        variant="masonry"
                        cols={isPC ? 3 : 2}
                        gap={8}
                        style={{ marginLeft: "16px" }}
                      >
                        {Object.keys(gear).map(key => {
                          const item = gear[key]
                          if (item.category_name === category) {
                            return (
                              <ImageListItem key={item.name}>
                                <PhotoView
                                  key={item.name}
                                  src={item.url}
                                  alt={item.name}
                                >
                                  <img
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    src="https://img.czhlove.cn/imgs/logo/lc_logo_2.png"
                                    srcSet={`${item.thumbsurl}`}
                                    alt={item.category_name || 'lc_logo'}
                                  />
                                </PhotoView>
                                <ImageListItemBar
                                  title={key}
                                  subtitle={isPC ? item.desc : ""}
                                />
                              </ImageListItem>
                            )
                          }else{
                            return <></>
                          }
                        })}
                      </ImageList>
                    </PhotoProvider>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        )}
      </Container>
    </Layout>
  )
}

export default CoolAlbumPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
